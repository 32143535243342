import React, { useEffect } from 'react';
import {
	Button,
	Modal,
	ModalProps,
	useSnackbar,
} from '@easerill/pixida-group-ui';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import {
	TextField,
	InputAdornment,
	Backdrop,
	CircularProgress,
	Autocomplete,
} from '@mui/material';
import { listGroups, changeDevicesGroup } from '../../api/group';
import useStateGroup from '../../hooks/state/useStateGroup';
import { Group } from '@farm/types/group';

/**
 * Props for the ChangeGroup component.
 */
export type ChangeGroupProps = {
	serialNumbers: readonly string[];
	onCancel: () => void;
	/* eslint-disable no-unused-vars */
	onAssign: (serialNumbers: readonly string[], group: Group) => void;
	/* eslint-enable no-unused-vars */
} & Omit<ModalProps, 'title' | 'onClose'>;

/**
 * Component to change group of devices.
 * @param {ChangeGroupProps} props - The props for the ChangeGroup component.
 * @returns The ChangeGroup component.
 */
const ChangeGroup = ({
	onCancel,
	onAssign,
	open,
	serialNumbers,
}: ChangeGroupProps) => {
	const { groups, setGroups } = useStateGroup();
	const [group, setGroup] = React.useState<Group | null>(null);
	const [loading, setLoading] = React.useState(false);
	const { triggerSnackbar } = useSnackbar();

	useEffect(() => {
		if (open) {
			setLoading(true);
			listGroups()
				.then((data) => {
					setGroups(data.groups);
				})
				.catch(() => {
					triggerSnackbar('Error on loading customers', 'error');
				})
				.finally(() => setLoading(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	/**
	 * Handles the click event when the user cancels the assignment process.
	 */
	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	/**
	 * Handles the click event when the user confirms the assignment of devices to the selected customer.
	 */
	const handleAssignClick = () => {
		if (!group) {
			return;
		}

		setLoading(true);

		changeDevicesGroup(serialNumbers, group.groupName)
			.then(() => {
				triggerSnackbar(
					`Successfully changed the group of ${serialNumbers.length} ${
						serialNumbers.length === 1 ? 'device' : 'devices'
					} to customer ${group.groupName}`,
					'success',
				);
				if (onAssign) {
					onAssign(serialNumbers, group);
				}
			})
			.catch(() => {
				triggerSnackbar(
					`There was an error updating the group of devices to group ${group.groupName}. Please check your email for details.`,
					'error',
				);
			})
			.finally(() => setLoading(false));
	};

	return (
		<Modal open={open} onClose={handleCancelClick} title="Change Group">
			<Backdrop
				sx={{
					color: '#fff',
					/**
					 * Sets the z-order of a positioned element
					 * @param {object} theme - The MUI theme object.
					 * @returns {number} The calculated z-index value for the backdrop.
					 */
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
				open={loading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Autocomplete
				id="customerId"
				options={Object.freeze(groups ?? [])}
				getOptionLabel={(option) => option.groupName}
				value={
					groups?.find((g: Group) => g.groupName === group?.groupName) || null
				}
				onChange={(event, group) => {
					setGroup(group);
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Group"
						placeholder="Select Group"
						InputProps={{
							...params.InputProps,
							startAdornment: !group ? (
								<InputAdornment position="start">
									<AutoAwesomeMotionIcon sx={{ marginRight: '5px' }} />
								</InputAdornment>
							) : null,
						}}
					/>
				)}
			/>
			<div style={{ marginLeft: 'auto', display: 'flex', gap: '16px' }}>
				<Button
					variation="info"
					text="Cancel"
					onClick={handleCancelClick}
					startIcon={<HighlightOffIcon />}
				/>
				<Button
					variation="success"
					text="Assign"
					onClick={handleAssignClick}
					startIcon={<CheckCircleOutlineIcon />}
					disabled={!group}
				/>
			</div>
		</Modal>
	);
};

export default ChangeGroup;
