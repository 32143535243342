import axios from './interceptors';
import { BASE_URL } from './constants';

/**
 * Calls the API to retrieve the groups.
 * @returns a list of devices
 */
export const listGroups = async () => {
	const response = await axios.get(`${BASE_URL}/v1/groups`);
	return response.data;
};

/**
 * Changes the group for multiple devices.
 * @param serialNumbers - An array of serial numbers of devices to be updated.
 * @param targetGroupName - The name of the group.
 */
export const changeDevicesGroup = async (
	serialNumbers: readonly string[],
	targetGroupName: string,
) => {
	await axios.patch(`${BASE_URL}/v1/devices/groups`, {
		serialNumbers,
		targetGroupName,
	});
};
